import { removeUiFields } from './remove-ui-fields';
import getSdkConnection from '@ion/app/src/pipelines-deprecated/get-sdk-connection';

const uiFields = [
  'expressionName',
  'opId',
  'opIndex',
  '_path',
  'event',
  'schema',
  '_hide',
  'pipelineVar',
  'attachmentKey',
];

export const downloadConnection = (connectionJSON, starterkitId) => {
  const { endpointSchema, connectionTemplate } = connectionJSON;

  if (endpointSchema) {
    return removeUiFields(endpointSchema, uiFields);
  }

  if (connectionTemplate) {
    const c = getSdkConnection({
      integrationName: starterkitId,
      connection: connectionTemplate,
      // vars can be empty because it's all be passed as defaultValue in pre-process-playbook
      vars: {},
      // pipelineVars are added in pipelines path. In playbook editor, it can be empty.
      pipelineVars: {},
      pipeline_variables: [],
    });

    return removeUiFields(c, uiFields);
  }

  return connectionJSON;
};
