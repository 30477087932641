import { useMockLazyQueryPipelines } from '@ion/api';

export default function usePipelinesTable() {
  const [getPipelines, { data, pagination, loading }] = useMockLazyQueryPipelines();

  return {
    onFetchPage: getPipelines,
    data,
    pagination,
    loading,
  };
}
