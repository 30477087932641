import React, { useEffect, useState } from 'react';
import pt from 'prop-types';
import { useHistory } from 'react-router-dom';

import { MrCard, MrErrorModal, generateId, useForm } from '@ion/components';
import { useDeployPipeline, useGetPipeline, useRemovePipelineIntegrations } from '@ion/api';

import { FormProvider } from 'app/context/form-context';
import buildPipelineJson from 'app/pipelines-deprecated/build-pipeline-json';

import PipelineCardHeader from './header';
import DeployModal from '../deploy-modal';

import getDeployStatus from '../get-deploy-status';
import getDisplayNames from '../get-display-names';
import parseDeployedIntegrations from '../parse-deployed-integrations';
import formatError from './format-error';
import { usePipelineCardContext } from './context/pipeline-card-context';
import BottomSection from './BottomSection';

import s from './index.module.scss';

const PipelineCard = ({ pipeline, cluster, author, integrationsData }) => {
  const { setPipeline } = usePipelineCardContext();
  const [fetchPipeline] = useGetPipeline();
  const [removePipelineIntegrations] = useRemovePipelineIntegrations();

  const stagedForDelete = pipeline.pipelineIntegrations.filter(
    pipelineIntegration => pipelineIntegration.stagedRevision.stagedForDelete === true
  );

  const { writekey, name } = pipeline;
  const { location } = useHistory();
  const formId = generateId();
  const { submitForm, formState, setField } = useForm(formId);
  const [lastDeployedDate, setLastDeployedDate] = useState();
  const [pipelineFilter, setPipelineFilter] = useState();
  const [pipelineTransform, setPipelineTransform] = useState();
  const getDeployedIntegrationRevisions = () => {
    fetchPipeline({
      clusterId: cluster.id,
      writekey,
    })
      .then(r => {
        if (r.errors) return;
        setLastDeployedDate(r.pipeline?.createdAt);
        setPipelineFilter(r.pipeline?.filter);
        setPipelineTransform(r.pipeline?.transform);
        setDeployedRevisions(
          parseDeployedIntegrations(r.pipeline.integrations, pipeline.pipelineIntegrations, integrationsData)
        );
        closeModal();
        setDeploying(false);
      })
      .catch(err => {
        if (err.toString().includes('action proxy error: control api status: 404')) {
          return [];
        } else console.log('err', err);
      });
  };

  // handle an expanded card via location state
  useEffect(() => {
    if (location.state && pipeline.id === location.state.expandedPipelineId) {
      setIsCardOpen(true);
    }
    setPipeline(pipeline);
  }, [location.state, location.state?.expandedPipelineId, pipeline, setPipeline]);

  const [isCardOpen, setIsCardOpen] = useState();
  const [pipelineJson, setPipelineJson] = useState();
  const [deployedRevisions, setDeployedRevisions] = useState([]);
  const [deployedRevisionsLoaded, setDeployedRevisionsLoaded] = useState(false);
  const [loadingDiff, setLoadingDiff] = useState(false);
  const [deploying, setDeploying] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [modalOpen, setModalOpen] = useState();
  const [variablesModalOpen, setVariablesModalOpen] = useState();
  const [revisionToEdit, setRevisionToEdit] = useState();

  if (integrationsData.length !== 0 && deployedRevisions.length === 0 && !deployedRevisionsLoaded) {
    getDeployedIntegrationRevisions();
    setDeployedRevisionsLoaded(true);
  }
  const closeModal = () => {
    setErrorMsg(null);
    setModalOpen(false);
  };

  const rebuildPipelineJson = () => {
    try {
      const json = buildPipelineJson(
        pipeline.pipelineIntegrations,
        author,
        writekey,
        pipelineFilter,
        pipelineTransform
      );
      setPipelineJson(json);
    } catch (error) {
      if (pipelineJson) {
        setErrorMsg(error.toString());
      }
      setPipelineJson({});
    }
  };

  const [deploy] = useDeployPipeline({
    onCompleted: () => {
      getDeployedIntegrationRevisions();
      removePipelineIntegrations(stagedForDelete);
    },
    onError: err => {
      setErrorMsg(formatError(err.errorMsg));
      setDeploying(false);
    },
  });

  const deployPipeline = () => {
    setDeploying(true);

    deploy({
      id: pipeline.id,
      name: pipeline.name,
      clusterId: cluster.id,
      writekey,
      pipelineJSON: pipelineJson,
    });
  };

  const deployStatus = getDeployStatus({
    cluster,
    deployedRevisions,
    pipelineIntegrations: pipeline.pipelineIntegrations,
    loadingDiff,
  });

  const displayNames = getDisplayNames(pipeline.pipelineIntegrations, deployedRevisions);

  if (!pipelineJson) {
    rebuildPipelineJson();
  }

  const openPipelineDiffModal = () => {
    setLoadingDiff(true);
    rebuildPipelineJson();
    setModalOpen(true);
  };

  return (
    <>
      <FormProvider value={{ formState, setField, submitForm }}>
        <MrCard
          closeBottom={!isCardOpen}
          className={s.card}
          top={
            <PipelineCardHeader
              writekey={writekey}
              pipeline={pipeline}
              name={name}
              isOpen={isCardOpen}
              changeOpen={setIsCardOpen}
              lastDeployedDate={lastDeployedDate}
              deployStatus={deployStatus}
              cluster={cluster}
              variablesModalOpen={variablesModalOpen}
              setVariablesModalOpen={setVariablesModalOpen}
              revisionToEdit={revisionToEdit}
              setRevisionToEdit={setRevisionToEdit}
              setDeployedRevisions={setDeployedRevisions}
              openPipelineDiffModal={openPipelineDiffModal}
            />
          }
          bottom={<BottomSection deployedRevisions={deployedRevisions} pipeline={pipeline} />}
        ></MrCard>
      </FormProvider>

      {modalOpen && (
        <DeployModal
          deploying={deploying}
          deployPipeline={deployPipeline}
          closeModal={closeModal}
          displayNames={displayNames}
          pipelineJson={pipelineJson}
          clusterId={cluster.id}
          writekey={writekey}
          setLoadingDiff={setLoadingDiff}
        />
      )}

      {errorMsg && <MrErrorModal header="Error Deploying Pipeline" errorMessage={errorMsg} closeModal={closeModal} />}
    </>
  );
};

PipelineCard.propTypes = {
  pipeline: pt.object.isRequired,
  cluster: pt.object,
  author: pt.string,
  integrationsData: pt.array,
};

export default PipelineCard;
