import { Link } from 'react-router-dom';

import { routes } from 'app/constants';
import { usePipelineCardContext } from 'app/pipelines-deprecated/card/context/pipeline-card-context';

const DEFAULT_BUTTON_PROPS = {
  variant: 'text',
  color: 'secondary',
};

export default function useSchemaViolationsButtonProps() {
  const { pipeline } = usePipelineCardContext();

  const linkProps = {
    component: Link,
    to: {
      pathname: routes.schemaViolations,
      state: { pipeline },
    },
  };

  return {
    ...DEFAULT_BUTTON_PROPS,
    ...linkProps,
  };
}
