import React from 'react';

import TextCell from 'app/components/data-table/TextCell';
import TimestampCell from 'app/components/data-table/TimestampCell';

export const pipelinesTableColumns = [
  {
    name: 'Pipeline Name',
    cell: row => <TextCell text={row.name} />,
  },
  // TODO (Chris 2025.02.11) - Integrations column is covered by a separate epic
  // {
  //   name: 'Integrations',
  //   cell: row => <IntegrationsCell integrations={row.pipelineIntegrations} />,
  // },
  {
    name: 'Writekey',
    cell: row => <TextCell text={row.writekey} />,
  },
  {
    name: 'Cluster',
    cell: row => <TextCell text={row.cluster.name} />,
  },
  // TODO (Chris 2025.02.11) - Need to create reusable PipelineStatusChip component
  // {
  //   name: 'Status',
  //   cell: row => <PipelineStatusChip status={row.status} />,
  // },
  {
    name: 'Last Deployed',
    cell: row => <TimestampCell timestamp={row.lastDeployedDate} />,
  },
];
