import { useDeploySchemasToPipeline } from '@ion/api';

import { usePipelineCardContext } from 'app/pipelines-deprecated/card/context/pipeline-card-context';

export default function useDeploySchemaLibrary({ pipelineSchemaLibraryId, onClose }) {
  const { pipeline } = usePipelineCardContext();
  const [deploySchemas] = useDeploySchemasToPipeline({ pipelineSchemaLibraryId, pipeline, onCompleted: onClose });

  return deploySchemas;
}
