import React from 'react';

import { PageReactDataTable } from '@metarouter-private/mr-mui';

import { pipelinesTableColumns } from './pipelinesTableColumns';
import usePipelinesTable from './usePipelinesTable';

const PIPELINES_PER_PAGE = 20;

export default function PipelinesTable() {
  const pipelinesTable = usePipelinesTable();

  return <PageReactDataTable columns={pipelinesTableColumns} perPage={PIPELINES_PER_PAGE} {...pipelinesTable} />;
}
