import React from 'react';

import { usePipelineCardContext } from 'app/pipelines-deprecated/card/context/pipeline-card-context';

import PipelineSchemaLibraryTableContextProvider from './PipelineSchemaLibraryTableContext';
import PipelineSchemaTable from './PipelineSchemaTable';

export default function PipelineSchemaSection() {
  const { pipelineLoading } = usePipelineCardContext();

  if (pipelineLoading) {
    return null;
  }

  return (
    <PipelineSchemaLibraryTableContextProvider>
      <PipelineSchemaTable />
    </PipelineSchemaLibraryTableContextProvider>
  );
}
