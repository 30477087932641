import { useState } from 'react';
import { range } from 'lodash';

const clusterNames = ['Roost Local Host', 'Staging', 'Production'];

const buildPipelines = ({ id }) => {
  return {
    cluster: { name: clusterNames[Math.floor(Math.random() * clusterNames.length)] },
    name: `Pipeline ${id}`,
    pipelineIntegrations: [{
      integrationId: 'integration-id',
      integration: {
        name: 'Integration Name',
      }
    }],
    writekey: `writekey-${id}`,
    // the following fields will be populated by the deployed pipeline data query to the control api
    lastDeployedDate: new Date(Date.now() - Math.floor(Math.random() * 7 * 24 * 60 * 60 * 1000)).toISOString(),
    status: 'Deployed',
  }
}

const pipelines = () => {
  return range(0, 45).map(number => {
    return buildPipelines({ id: number });
  })
}

export default function useMockLazyQueryPipelines() {
  const [response, setResponse] = useState({ data: [], pagination: { count: null }});
  const [loading, setLoading] = useState(false);

  const executeQuery =  ({ page, perPage }) => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const data = pipelines().slice(startIndex, endIndex);
    setTimeout(() => {
      setResponse({
        data,
        pagination: { count: pipelines().length },
      });
      setLoading(false);
    }, 800)
    setLoading(true);
  };

  return [executeQuery, { ...response, loading }];
}