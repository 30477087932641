import React from 'react';

import { MrAppLayout, MrAppMain } from '@ion/components';
import { useTrackPage } from '@ion/api';

import SideNav from 'app/components/side-nav';
import Header from 'app/components/app-header';
import PipelinesTable from './PipelinesTable';

export default function PipelinesPage() {
  useTrackPage('pipelines_list_page_viewed');
  return (
    <MrAppLayout
      title="Pipelines"
      description="Manage your data pipelines."
      sideNav={<SideNav />}
      header={<Header icon="pipelines" title="Pipelines" />}
    >
      <MrAppMain>
        <PipelinesTable />
      </MrAppMain>
    </MrAppLayout>
  );
}
