import actions from '@ion/api/audit-logs/actions';
import styles from './color-coding.module.scss';

export function colorClass(action) {
  const color = COLOR_CODING[action];
  return styles[color] ?? '';
}

export const COLOR_CODING = {
  [actions.ajs_file_built]: 'orange',
  [actions.auth_logged_in]: 'gray',
  [actions.auth_logged_out]: 'gray',
  [actions.consent_category_created]: 'blue',
  [actions.consent_category_deleted]: 'red',
  [actions.consent_category_updated]: 'purple',
  [actions.event_name_created]: 'blue',
  [actions.event_name_deleted]: 'red',
  [actions.event_name_updated]: 'purple',
  [actions.event_schema_deleted]: 'red',
  [actions.integration_added_to_pipeline]: 'green',
  [actions.integration_created]: 'blue',
  [actions.integration_deleted]: 'red',
  [actions.integration_removed_from_pipeline]: 'red',
  [actions.integration_updated]: 'purple',
  [actions.pipeline_created]: 'blue',
  [actions.pipeline_deleted]: 'red',
  [actions.pipeline_deployed]: 'green',
  [actions.pipeline_integration_updated]: 'purple',
  [actions.pipeline_updated]: 'purple',
  [actions.pipeline_variable_updated]: 'purple',
  [actions.schema_created]: 'blue',
  [actions.schema_deleted]: 'red',
  [actions.schema_updated]: 'purple',
  [actions.schema_library_created]: 'blue',
  [actions.schema_library_updated]: 'purple',
  [actions.schema_library_deleted]: 'red',
  [actions.schema_library_published]: 'purple',
  [actions.schema_library_added_to_pipeline]: 'green',
  [actions.schema_library_removed_from_pipeline]: 'red',
  [actions.user_deleted]: 'red',
  [actions.user_invited]: 'blue',
  [actions.user_role_edited]: 'purple',
};
