import actions from '@ion/api/audit-logs/actions';
import invert from 'lodash/invert';

export const ACTION_TO_READABLE_ACTION = {
  [actions.ajs_file_built]: 'A.js File Built',
  [actions.auth_logged_in]: 'Logged In',
  [actions.auth_logged_out]: 'Logged Out',
  [actions.consent_category_created]: ' Consent Category Created',
  [actions.consent_category_deleted]: 'Consent Category Deleted',
  [actions.consent_category_updated]: 'Consent Category Updated',
  [actions.event_name_created]: 'Event Created',
  [actions.event_name_deleted]: 'Event Deleted',
  [actions.event_name_updated]: 'Event Updated',
  [actions.event_schema_deleted]: 'Event Schema Deleted',
  [actions.integration_added_to_pipeline]: 'Integration Added',
  [actions.integration_created]: 'Integration Created',
  [actions.integration_deleted]: 'Integration Deleted',
  [actions.integration_removed_from_pipeline]: 'Integration Removed',
  [actions.integration_updated]: 'Integration Updated',
  [actions.pipeline_created]: 'Pipeline Created',
  [actions.pipeline_deployed]: 'Pipeline Deployed',
  [actions.pipeline_deleted]: 'Pipeline Deleted',
  [actions.pipeline_integration_updated]: 'Pipeline Integration Updated',
  [actions.pipeline_updated]: 'Pipeline Updated',
  [actions.pipeline_variable_updated]: 'Pipeline Variable Updated',
  [actions.schema_created]: 'Schema Created',
  [actions.schema_deleted]: 'Schema Deleted',
  [actions.schema_updated]: 'Schema Updated',
  [actions.schema_library_created]: 'Schema Library Created',
  [actions.schema_library_updated]: 'Schema Library Updated',
  [actions.schema_library_deleted]: 'Schema Library Deleted',
  [actions.schema_library_published]: 'Schema Library Published',
  [actions.schema_library_added_to_pipeline]: 'Schema Library Added',
  [actions.schema_library_removed_from_pipeline]: 'Schema Library Removed',
  [actions.user_deleted]: 'User Deleted',
  [actions.user_invited]: 'User Invited',
  [actions.user_role_edited]: 'User Role Edited',
};

export const READABLE_ACTION_TO_ACTION = invert(ACTION_TO_READABLE_ACTION);

export const READABLE_ACTIONS = Object.values(ACTION_TO_READABLE_ACTION);

export function readableAction(action) {
  return ACTION_TO_READABLE_ACTION[action];
}
