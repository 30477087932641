import React from 'react';

import { MrAppLayout, MrAppMain } from '@ion/components';
import { Box, Stack, Grid } from '@metarouter-private/mr-mui';

import SideNav from 'app/components/side-nav';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';
import RedirectFeatureGate from 'app/launch-darkly/gates/redirect-feature-gate';

import PipelineDetailsPageHeader from './PipelineDetailsPageHeader';

export default function PipelineDetailsPage() {
  return (
    <RedirectFeatureGate flagName={FEATURE_FLAGS.pipelinesListRevamp}>
      <MrAppLayout title="Pipelines" header={<PipelineDetailsPageHeader />} sideNav={<SideNav />}>
        <MrAppMain>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Box>Cluster Details Table</Box>
              <Box>Ajs file Table</Box>
              <Box>Schema Enforcement Table</Box>
            </Grid>

            <Box>Pipeline Integrations Table</Box>
          </Stack>
        </MrAppMain>
      </MrAppLayout>
    </RedirectFeatureGate>
  );
}
