import React from 'react';

import { routes } from 'app/constants';
import AppHeader from 'app/components/app-header';

export default function PipelineDetailsPageHeader() {
  const subTitle = `Will Replace to Pipeline Name`;

  return <AppHeader icon="pipelines" title="Pipelines" route={routes.pipelines} subTitle={subTitle} />;
}
