export const FEATURE_FLAGS = {
  aceEditor: 'aceEditor',
  builderServerAdvancedSettings: 'builderServerAdvancedSettings',
  eventDebuggerMvp: 'eventDebuggerMvp',
  intendedSchemaMvp: 'intendedSchemaMvp',
  pipelinesListRevamp: 'pipelinesListRevamp',
  roUserManagementMVP: 'roUserMgmtMvp',
  uploadAjsFileToCluster: 'uploadAjsFileToCluster',
  splitGoogleTagSettings: 'splitGoogleTagSettings',
};
